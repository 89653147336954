<script>
import { removeRaiLoading, addRaiLoading } from "@/utils/preloader";
export default {
  name: "Loading",
  title: "ResaleAI",
  beforeRouteEnter(to, from, next) {
    // earliest event in the lifecycle when component already created
    addRaiLoading();
    next();
  },
  computed: {
    storeId() {
      if (this.$route.query.sid) return this.$route.query.sid;
      return (
        this.$store.getters["auth/activeStoreId"] ||
        this.$store.getters["auth/currentUserDefaultStoreId"]
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      // If there is a redirect param, redirect to root and then to that (it means we have a case of landing to direct link)
      if (this.$route.query?.redirectTo) {
        const redirectTo = this.$route.query?.redirectTo;
        this.$router.replace({ path: "/" }, () => {
          this.$router.push(redirectTo);
        });
      } else {
        this.$router.push(`/s/${this.storeId}`);
      }
    });
  },
  // very last stage of component life-cycle
  destroyed() {
    removeRaiLoading();
  },
};
</script>

<template>
  <div>
    <!-- loading -->
  </div>
</template>
